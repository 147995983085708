import './assets/main.css'
import '@unocss/reset/tailwind-compat.css'
import 'virtual:uno.css'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import globalComponents from '@/components'
import globalDirectives from '@/directives'
import App from './App.vue'
import router from './router'

createApp(App)
  .use(createPinia())
  .use(globalComponents)
  .use(globalDirectives)
  .use(router)
  .mount('#app')
