<script setup lang="ts">
  import { useLogout } from '@/models/app'

  const logout = useLogout()
</script>

<template>
  <app-button
    class="size-8 flex-center text-xl text-gray-600"
    title="Logout"
    @click="logout()">
    <fa-icon icon="i-far-arrow-right-from-bracket"></fa-icon>
  </app-button>
</template>
