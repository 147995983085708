<script setup lang="ts">
  import type { SelectHTMLAttributes } from 'vue'
  import { computed, ref } from 'vue'
  import { randomId } from '@/functions'

  const modelValue = defineModel<SelectHTMLAttributes['value']>()

  const props = defineProps<{
    id?: string,
    icon?: string,
    disabled?: boolean,
    label?: string,
    required?: boolean,
    error?: string | string[],
    placeholder?: string,
    loading?: boolean,
  }>()

  const focus = ref(false)

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? defaultId)
</script>

<template>
  <div>
    <label
      v-if="label"
      :for="inputId"
      class="form-label">
      {{ label }}
      <span
        v-if="required"
        class="text-danger-600">
        *
      </span>
    </label>
    <div class="relative">
      <select
        :id="inputId"
        v-model="modelValue"
        :required="required"
        :disabled="disabled"
        class="form-control"
        @focus="focus = true"
        @blur="focus = false">
        <option
          v-if="placeholder"
          :value="null"
          hidden>
          {{ placeholder }}
        </option>
        <slot></slot>
      </select>
      <div class="pointer-events-none absolute inset-y-0 right-2 flex items-center text-xs text-gray">
        <fa-icon icon="i-far-angle-down"></fa-icon>
      </div>
    </div>
    <app-error-msg :msg="error"></app-error-msg>
  </div>
</template>

<style lang="postcss" scoped>
  /* border on the container div, so that the focus ring enclose the icon as well */
  /* then the select height should less than h-9 */
  select {
    @apply cursor-pointer appearance-none pr-7;
  }
</style>
