<script setup lang="ts">
  import TheToast from '@/components/the-toast.vue'
  import { useAuthenticatedRedirect, useInit, useRenderPage } from './models/app'
  import { useAppStore } from './stores/app'

  const app = useAppStore()
  setInterval(app.incrementSecondPassed, 1000)

  const { initApp } = useInit()
  initApp()

  useAuthenticatedRedirect()

  const { renderPage } = useRenderPage()
</script>

<template>
  <div v-show="renderPage">
    <app-router-view></app-router-view>
  </div>
  <the-toast></the-toast>
</template>
