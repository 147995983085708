import { defineStore } from 'pinia'

const APP_TITLE = import.meta.env.VITE_APP_TITLE ?? 'Gradin Digital Agency'

export const usePageStore = defineStore('Page', {
  state: () => ({
    title: '',
    maintenance: false,
    unreadNotificationsCount: 0,
  }),
  actions: {
    setTitle (title: string) {
      setTimeout(() => {
        this.title = title
        document.title = `${this.title} | ${APP_TITLE}`
      }, 250)
    },
    setMaintenance (maintenance: boolean) {
      this.maintenance = maintenance
    },
    setUnreadNotificationsCount (count: number) {
      this.unreadNotificationsCount = count
    },
  },
})
