<script setup lang="ts">
</script>

<template>
  <app-button
    class="size-8 flex-center text-xl text-gray-600"
    :to="{name: 'change password'}"
    title="Ganti Password">
    <fa-icon icon="i-far-key"></fa-icon>
  </app-button>
</template>
