<script setup lang="ts">
  import {
    Dialog as AppDialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue'
  import { useModal } from '.'

  defineProps<{
    title?: string,
    description?: string,
    size?: 'small' | 'medium' | 'large',
  }>()

  const { isOpen, open, close } = useModal()

  defineExpose({ open, close })
</script>

<template>
  <teleport to="body">
    <transition-root
      appear
      :show="isOpen"
      as="template">
      <app-dialog
        as="div"
        class="relative z-30"
        @close="close">
        <transition-child
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <div class="fixed inset-0 bg-black bg-opacity-40"></div>
        </transition-child>

        <div class="fixed inset-0 flex-center sm:p-4">
          <transition-child
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <dialog-panel
              class="dialog-panel"
              :class="{
                'max-w-4xl': size === 'large',
                'max-w-xl': size === 'medium',
                'max-w-100': !['large', 'medium'].includes(size ?? 'small'),
              }">
              <button
                class="absolute right-4 top-5 h-8 w-8 flex-center rounded-full text-gray-500 hover:bg-gray-200"
                @click="close">
                <fa-icon icon="i-fas-xmark"></fa-icon>
              </button>

              <app-modal-title v-if="title">
                {{ title }}
              </app-modal-title>

              <app-modal-content>
                <slot></slot>
              </app-modal-content>
            </dialog-panel>
          </transition-child>
        </div>
      </app-dialog>
    </transition-root>
  </teleport>
</template>

<style lang="postcss" scoped>
  .dialog-panel {
    @apply transform rounded-2xl bg-white p-6 pb-20 text-left align-middle shadow-xl transition-all w-full;
  }
  :deep() {
    .dialog-content {
      /* ensure the modal content height will not surpass screen height */
      /* class from app-modal-content */
      @apply flex overflow-y-auto flex-col max-h-[calc(100vh_-_8rem)] lg:max-h-[calc(100vh_-_12rem)] 2xl:max-h-[calc(100vh_-_16rem)];
      @apply p-1 pr-2.5 scrollbar-stable -m-1 -mr-4;
    }
  }
</style>
