import type { App } from 'vue'
import AppButton from './app-button.vue'
import AppButtonLogout from './app-button-logout.vue'
import AppButtonPasswordChange from './app-button-password-change.vue'
import AppErrorMsg from './app-error-msg.vue'
import AppInput from './app-input.vue'
import AppInputPassword from './app-input-password.vue'
import AppLoadingDot from './app-loading-dot.vue'
import AppRouterView from './app-router-view.vue'
import AppSelect from './app-select.vue'
import AppTitle from './app-title.vue'
import FaIcon from './fa-icon.vue'
import Modal from './modal'

export default {
  install (app: App) {
    app.component('AppButton', AppButton)
    app.component('AppButtonLogout', AppButtonLogout)
    app.component('AppButtonPasswordChange', AppButtonPasswordChange)
    app.component('AppErrorMsg', AppErrorMsg)
    app.component('AppInput', AppInput)
    app.component('AppInputPassword', AppInputPassword)
    app.component('AppLoadingDot', AppLoadingDot)
    app.component('AppRouterView', AppRouterView)
    app.component('AppSelect', AppSelect)
    app.component('AppTitle', AppTitle)

    app.component('FaIcon', FaIcon)

    app.use(Modal)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppButton: typeof AppButton,
    AppButtonLogout: typeof AppButtonLogout,
    AppButtonPasswordChange: typeof AppButtonPasswordChange,
    AppErrorMsg: typeof AppErrorMsg,
    AppInput: typeof AppInput,
    AppInputPassword: typeof AppInputPassword,
    AppLoadingDot: typeof AppLoadingDot,
    AppRouterView: typeof AppRouterView,
    AppSelect: typeof AppSelect,
    AppTitle: typeof AppTitle,
    FaIcon: typeof FaIcon,
  }
}
