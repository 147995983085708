<script setup lang="ts">
  import { computed, ref } from 'vue'

  const props = defineProps<{
    label?: string,
    sublabel?: string,
    modelValue: string,
    error?: string | string[],
    autocomplete?: string,
    placeholder?: string,
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void,
  }>()

  const value = computed({
    set: (text: string) => {
      emit('update:modelValue', text)
    },
    get: () => props.modelValue,
  })

  const showPassword = ref(false)
</script>

<template>
  <app-input
    v-model="value"
    :label="label"
    :sublabel="sublabel"
    :autocomplete="autocomplete"
    :type="showPassword ? 'text' : 'password'"
    :placeholder="placeholder"
    required
    :error="error">
    <template v-if="value">
      <label
        class="absolute right-2 top-0 h-9 flex cursor-pointer select-none items-center p-1 text-gray-400 font-bold">
        <input
          v-model="showPassword"
          tabindex="99"
          type="checkbox"
          class="opacity-0"
          name="show-hide-password">
        <fa-icon
          v-if="showPassword"
          icon="i-fal-eye-slash"></fa-icon>
        <fa-icon
          v-else
          icon="i-fal-eye"></fa-icon>
      </label>
    </template>
  </app-input>
</template>

<style lang="postcss" scoped>
  :deep(input[type='password']),
  :deep(input[type='text']) {
    @apply pr-12;
  }
</style>
