import { defineStore } from 'pinia'

interface State {
  authenticated: 'loading' | 'logging-out' | 'logging-in' | boolean,
  user: User | null,
  attendances_today: Attendance[],
  server_time: Date,
  second_passed: number,
}

export const useAppStore = defineStore('App', {
  state: (): State => ({
    authenticated: 'loading',
    user: null,
    attendances_today: [],
    server_time: new Date(),
    second_passed: 0,
  }),
  getters: {
    isWorking (state) {
      return !!state.attendances_today.find(a => !a.out)
    },
  },
  actions: {
    setAuthenticated (authenticated: State['authenticated']) {
      this.authenticated = authenticated
    },
    setUser (user: User | null) {
      this.user = user
    },
    setServerTime (server_time: string) {
      this.server_time = new Date(server_time)
      this.second_passed = 0
    },
    setAttendancesToday (attendances: Attendance[]) {
      this.attendances_today = attendances
    },
    updateAttendance (attendance: Attendance) {
      // check if attendance exist on attendances_today, update value if exist
      // otherwisse push new attendance
      if (this.attendances_today.find(a => a.id === attendance.id)) {
        this.attendances_today = this.attendances_today.map(a => {
          return a.id === attendance.id ? attendance : a
        })
      } else {
        this.attendances_today.push(attendance)
      }
    },
    incrementSecondPassed () {
      this.second_passed++
    },
  },
})
