<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import { randomId } from '@/functions'

  defineOptions({
    inheritAttrs: false,
  })

  const props = defineProps<{
    id?: string,
    label?: string,
    modelValue: string | number,
    sublabel?: string,
    placeholder?: string,
    type?:
      | 'text'
      | 'textarea'
      | 'tel'
      | 'email'
      | 'password'
      | 'search',
    required?: boolean,
    error?: string | string[],
    autocomplete?: string,
    maxlength?: string | number,
    disabled?: boolean,
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', val: string | number): void,
  }>()

  const value = computed({
    get: () => props.modelValue,
    set: (val) => {
      emit('update:modelValue', val)
    },
  })

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? defaultId)

  // hide error message when value is changed after the error message is shown
  const oldValue = ref<string | number>('')
  const isDirty = computed(() => value.value !== oldValue.value)
  watch(
    () => props.error,
    () => {
      oldValue.value = value.value
    },
    { immediate: true },
  )
</script>

<template>
  <div :class="{ 'has-error': !!error && !isDirty }">
    <div class="space-y-1">
      <label
        v-if="label"
        :for="inputId"
        class="form-label">
        {{ label }}
        <template v-if="required">
          <span class="form-required inline-block text-red-600">*</span>
        </template>
      </label>
      <span
        v-if="sublabel"
        class="form-sublabel">
        ({{ sublabel }})
      </span>
      <div class="relative">
        <template v-if="type === 'textarea'">
          <textarea
            :id="inputId"
            v-model="value"
            v-bind="$attrs"
            rows="3"
            class="form-control"
            :maxlength="maxlength"
            :required="required ?? false"
            :placeholder="placeholder"
            :disabled="disabled"></textarea>
        </template>
        <template v-else>
          <input
            :id="inputId"
            v-model="value"
            v-bind="$attrs"
            class="form-control"
            :type="type ?? 'text'"
            :maxlength="maxlength"
            :required="required ?? false"
            :disabled="disabled"
            :autocomplete="autocomplete"
            :placeholder="placeholder">
        </template>
        <slot></slot>
      </div>
    </div>
    <div>
      <app-error-msg :msg="error"></app-error-msg>
    </div>
  </div>
</template>

<style lang="postcss">
  .form-label {
    @apply text-xs font-bold;
    @apply select-none;
  }
  .form-control {
    @apply block w-full text-sm;
    @apply rounded-lg h-9; /* same as .btn */
    @apply border border-gray-200 placeholder:text-gray-300 focus:border-primary;
    @apply px-4; /* spacing text around input border */
  }
  .form-sublabel {
    @apply text-xs text-gray;
  }
  .has-error .form-control {
    @apply border-red;
  }
  .has-error .form-label {
    @apply text-red;
  }
  textarea.form-control {
    @apply leading-inherit h-inherit py-2;
  }
</style>
<style lang="postcss" scoped>
  .text-center .form-control,
  .text-center .form-label {
    text-align: center;
  }
</style>
