export default [
  {
    path: '/',
    component: () => import('@/views/app/user/layout-user.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/app/user/home/page-home.vue'),
      },
      {
        path: '/ganti-password',
        name: 'change password',
        component: () => import('@/views/app/page-change-password.vue'),
      },
    ],
  },
  {
    path: '/admin',
    component: () => import('@/views/app/admin/layout-admin.vue'),
    children: [
      {
        path: '/laporan/absensi',
        name: 'attendance report',
        component: () => import('@/views/app/admin/report/page-report.vue'),
      },
      {
        path: '/pengguna',
        name: 'user list',
        component: () => import('@/views/app/admin/user/page-user.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: () => import('@/views/auth/layout-auth.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/login/page-login.vue'),
      },
    ],
  },
]
