import { watchDebounced } from '@vueuse/core'
import { reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useApi } from '@/functions/api'
import { useAppStore } from '@/stores/app'

export type InitResponse = {
  server_time: string,
  user: User,
  attendances_today: Attendance[],
}

export function useInit () {
  const app = useAppStore()
  const api = useApi()

  const initApp = async () => {
    // redirect to login page is handled by watch(app.authenticated) below
    const response = await api.GET<InitResponse>('user/app/init')
    app.setAuthenticated(true)
    setInitResponse(response)

  }
  const setInitResponse = (response: InitResponse) => {
    app.setUser(response.user)
    app.setServerTime(response.server_time)
    app.setAttendancesToday(response.attendances_today)
  }

  return {
    initApp,
    setInitResponse,
  }
}

export function useAuthenticatedRedirect () {
  const route = useRoute()
  const router = useRouter()
  const app = useAppStore()

  const storageKey = 'unauthenticated_redirected_from'
  const redirectFrom = {
    set: (path: string) => sessionStorage.setItem(storageKey, path),
    get: () => sessionStorage.getItem(storageKey),
    clear: () => sessionStorage.removeItem(storageKey),
  }

  const redirectToUserHome = async () => {
    if (app.user === null) {
      return
    }
    if (app.user?.role === 'Admin') {
      await router.replace({ name: 'attendance report' })
    } else {
      await router.replace({ name: 'home' })
    }
  }

  watch(() => app.authenticated, async (newValue) => {
    if (newValue === 'loading') {
      return
    }
    if (newValue === false && route.name === 'login') {
      return // not logged in but opening login page, do nothing
    }
    if (newValue === true && route.name !== 'login') {
      return // logged in opening regular page, do nothing
    }

    if (newValue === 'logging-out') {
      app.setUser(null)
      await router.replace({ name: 'login' })
      app.setAuthenticated(false)
      return
    }

    if (newValue === 'logging-in') {
      // app.authenticated updated after login success,
      const redirectTo = redirectFrom.get()
      if (redirectTo) {
        await router.replace(redirectTo)
        redirectFrom.clear()
      } else {
        await redirectToUserHome()
      }
      app.setAuthenticated(true)
      return
    }

    if (newValue === false && route.name !== 'login') {
      // not logged in and opening regular page, store current url, redirect to login
      if (route.name !== 'home') {
        redirectFrom.set(route.fullPath)
      }
      await router.replace({ name: 'login' })
      return
    }

    if (newValue === true && route.name === 'login') {
      // logged in but accessing login page from url directly
      // redirect to previous page if available, or to home page based on user role
      redirectToUserHome()
    }
  })
}

export function useRenderPage () {
  const app = useAppStore()
  const renderPage = ref(false)
  const routerViewTransitionDuration = 200
  watchDebounced(
    () => app.authenticated,
    async (newValue) => {
      renderPage.value = typeof newValue === 'boolean'
    },
    { debounce: routerViewTransitionDuration },
  )

  return {
    renderPage,
  }
}

export function useLogin () {
  const app = useAppStore()
  const api = useApi()

  const { setInitResponse } = useInit()

  const form = reactive({
    username: '',
    password: '',
  })
  const submitting = ref(false)
  const errors = ref<FormError>({})

  const submit = async () => {
    try {
      submitting.value = true
      errors.value = {}
      const response = await api.POST<InitResponse>('login', {
        username: form.username,
        password: form.password,
        remember: true,
      })
      setInitResponse(response)
      setTimeout(() => {
        app.setAuthenticated('logging-in')
      }, 250)
    } catch (error) {
      errors.value = api.formErrors(error)
      submitting.value = false
    }
  }

  return {
    form,
    submitting,
    errors,
    submit,
  }
}

export function useLogout () {
  const api = useApi()
  const app = useAppStore()

  return () => {
    app.setAuthenticated('logging-out')
    api.POST('logout')
  }
}
